.Button {
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  background: transparent;
  font-family: "Catamaran"; }
  .Button.primary {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Catamaran";
    color: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    background: #2918e2;
    background: #2918e2;
    background: linear-gradient(90deg, #2918e2 0%, #6236a3 100%, #705b8f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2918e2", endColorstr="#705b8f", GradientType=1); }
    .Button.primary:hover, .Button.primary:focus {
      transition: 0.4s;
      border: 1px solid #b19fd8;
      background: #705b8f;
      background: linear-gradient(90deg, #705b8f 0%, #2918e2 100%, #b19fd8 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#705b8f", endColorstr="#b19fd8", GradientType=1); }
    .Button.primary:disabled {
      opacity: 0.8; }
  .Button.google {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    background-color: #ffffff;
    border-width: 0;
    border: 1px solid #e8e8e8;
    color: #737373;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
    transition-property: background-color, box-shadow;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out; }
    .Button.google .google__icon {
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      box-sizing: border-box;
      margin-right: 20px; }
    .Button.google:focus, .Button.google:hover {
      box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1); }
    .Button.google:active {
      background-color: #e5e5e5;
      box-shadow: none;
      transition-duration: 10ms; }
  .Button.basic {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Catamaran";
    color: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    background: linear-gradient(#b19fd8, #a7cff1 90%);
    transition: 0.4s; }
    .Button.basic:hover, .Button.basic:focus {
      background: linear-gradient(#a7cff1, #b19fd8 90%); }
  .Button.Center {
    width: 100%; }

@media (max-width: 768px) {
  .Button {
    height: 10.29412vw;
    border-radius: 1.17647vw;
    font-size: 3.82353vw; } }

.input-group label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 12px;
  display: block; }

.input-group input {
  width: 100%;
  border-radius: 2px;
  outline: none;
  font-family: 'Catamaran';
  padding: 5px 10px;
  border: 2px solid #cad5e1;
  border-radius: 4px;
  color: #40445b;
  font-size: 13px; }
  .input-group input::-webkit-input-placeholder {
    color: #cad5e1; }
  .input-group input::-ms-input-placeholder {
    color: #cad5e1; }
  .input-group input::placeholder {
    color: #cad5e1; }

@media (max-width: 768px) {
  .input-group input {
    border-radius: 0.58824vw;
    padding: 1.47059vw 2.94118vw;
    border: 0.58824vw solid #cad5e1;
    border-radius: 1.17647vw; } }

.LoginWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#a7cff1 0%, #b19fd8 64%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a7cff1",endColorstr="#b19fd8",GradientType=1); }
  .LoginWrapper .LoginWrapper__form {
    border-radius: 5px;
    background-color: #ffffff;
    height: 90vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden; }
    .LoginWrapper .LoginWrapper__form-image {
      background: url(/static/media/login.5d5bd6ee.jpg) center no-repeat;
      background-size: cover;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      transition: -webkit-transform 1s ease;
      transition: transform 1s ease;
      transition: transform 1s ease, -webkit-transform 1s ease; }
      .LoginWrapper .LoginWrapper__form-image.translate {
        -webkit-animation: translate-left 1s ease;
                animation: translate-left 1s ease;
        -webkit-transform: translate(-100%, 0);
                transform: translate(-100%, 0); }
    .LoginWrapper .LoginWrapper__form-content {
      align-self: center;
      justify-self: center;
      padding: 60px;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      transition: -webkit-transform 1s ease;
      transition: transform 1s ease;
      transition: transform 1s ease, -webkit-transform 1s ease; }
      .LoginWrapper .LoginWrapper__form-content h1 {
        font-size: 40px;
        line-height: 57px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #40445b; }
      .LoginWrapper .LoginWrapper__form-content p {
        color: #8e9db0;
        font-size: 15px;
        margin-bottom: 30px; }
      .LoginWrapper .LoginWrapper__form-content form {
        display: grid;
        grid-row-gap: 25px;
        padding-bottom: 20px; }
      .LoginWrapper .LoginWrapper__form-content span {
        font-size: 12px; }
        .LoginWrapper .LoginWrapper__form-content span .Button {
          font-size: 12px;
          text-decoration: underline;
          color: #8e9db0; }
      .LoginWrapper .LoginWrapper__form-content.translate {
        -webkit-animation: translate-right 1s ease;
                animation: translate-right 1s ease;
        -webkit-transform: translate(100%, 0);
                transform: translate(100%, 0); }

@-webkit-keyframes translate-left {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  to {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); } }

@keyframes translate-left {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  to {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); } }

@-webkit-keyframes translate-right {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  to {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); } }

@keyframes translate-right {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  to {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); } }

@media (max-width: 768px) {
  .LoginWrapper .LoginWrapper__form {
    height: 95vh;
    width: 90%;
    grid-template-columns: none;
    background: url(/static/media/login-mobile.646b2c98.png) center no-repeat;
    background-size: cover;
    border-radius: 1.47059vw; }
    .LoginWrapper .LoginWrapper__form-image {
      display: none; }
    .LoginWrapper .LoginWrapper__form-content {
      padding: 0; }
      .LoginWrapper .LoginWrapper__form-content h1 {
        font-size: 11.76471vw;
        line-height: 16.76471vw;
        margin-bottom: 2.94118vw;
        color: #f9fafb; }
      .LoginWrapper .LoginWrapper__form-content p {
        font-size: 4.41176vw;
        margin-bottom: 8.82353vw; }
      .LoginWrapper .LoginWrapper__form-content form {
        grid-row-gap: 7.35294vw;
        padding-bottom: 5.88235vw; }
      .LoginWrapper .LoginWrapper__form-content span {
        font-size: 12px;
        color: #b19fd8; }
        .LoginWrapper .LoginWrapper__form-content span .Button {
          color: #8e9db0; }
  .Register,
  .Login {
    opacity: 0;
    transition: opacity 0.4s; }
    .Register.active,
    .Login.active {
      opacity: 1; } }

.MenuButton {
  background: none;
  cursor: pointer;
  position: fixed;
  top: 50px;
  right: 20px;
  z-index: 99; }
  .MenuButton:before {
    content: "";
    background: white;
    width: 45px;
    height: 45px;
    position: absolute;
    top: -26px;
    left: -4px;
    border-radius: 50%; }
  .MenuButton.is-open:before {
    content: none; }

.burger {
  position: relative;
  display: block;
  width: 28px;
  height: 4px;
  margin: 0 auto;
  background: #5a3b5d;
  -webkit-transform: skew(5deg);
          transform: skew(5deg);
  transition: all 0.275s;
  outline: none; }

.burger:after,
.burger:before {
  content: "";
  display: block;
  height: 100%;
  background: #5a3b5d;
  transition: all 0.275s; }

.burger:after {
  -webkit-transform: translateY(-12px) translateX(-2px) skew(-20deg);
          transform: translateY(-12px) translateX(-2px) skew(-20deg); }

.burger:before {
  -webkit-transform: translateY(-16px) skew(-10deg);
          transform: translateY(-16px) skew(-10deg); }

.is-open .burger {
  -webkit-transform: skew(5deg) translateY(-8px) rotate(-45deg);
          transform: skew(5deg) translateY(-8px) rotate(-45deg); }

.is-open .burger:before {
  -webkit-transform: translateY(0px) skew(-10deg) rotate(75deg);
          transform: translateY(0px) skew(-10deg) rotate(75deg); }

.is-open .burger:after {
  -webkit-transform: translateY(-12px) translateX(10px) skew(-20deg);
          transform: translateY(-12px) translateX(10px) skew(-20deg);
  opacity: 0; }

.Menu {
  color: #ffffff;
  grid-area: m;
  position: relative;
  overflow: hidden;
  background: linear-gradient(#b19fd8, #a7cff1 90%); }
  .Menu__user {
    display: flex;
    align-items: center;
    padding: 30px;
    border-bottom: 1px solid #cacad9; }
    .Menu__user .username {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between; }
      .Menu__user .username > span:first-child {
        font-size: 17px;
        line-height: 20px; }
      .Menu__user .username > span:nth-child(2) {
        font-size: 13px; }
    .Menu__user .avatar {
      border-radius: 50%;
      overflow: hidden;
      height: 40px;
      width: 40px;
      margin-right: 20px; }
      .Menu__user .avatar > img {
        width: 100%; }
  .Menu__sections {
    margin: 30px 0 70px; }
    .Menu__sections ul li {
      font-weight: 500;
      letter-spacing: 0.6px;
      cursor: pointer; }
      .Menu__sections ul li a {
        display: block;
        width: 100%;
        padding: 20px 0 20px 30px; }
      .Menu__sections ul li:hover {
        color: #76728f; }
      .Menu__sections ul li.active {
        background: linear-gradient(90deg, #d1c8e4 10%, #cacad9 112%);
        color: #76728f; }

@media (max-width: 768px) {
  .Menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    opacity: 0;
    z-index: -1; }
    .Menu.active {
      opacity: 1;
      z-index: 1; } }

.Button__signOut {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #ffffff;
  font-size: 15px;
  cursor: pointer; }
  .Button__signOut:hover {
    color: #76728f; }
  .Button__signOut.active {
    background: linear-gradient(90deg, #d1c8e4 10%, #cacad9 112%);
    color: #76728f; }
  .Button__signOut img {
    width: 20px;
    height: 20px; }

.AppHeader {
  grid-area: h;
  background-color: #ffffff;
  padding: 20px 0;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #cacad9; }
  .AppHeader__select {
    width: 100%;
    max-width: 350px; }

@media (max-width: 768px) {
  .AppHeader__select {
    width: 70%; } }

.AppContent {
  grid-area: c;
  background-color: #ffffff;
  padding: 20px 40px; }

@media (max-width: 768px) {
  .AppContent {
    padding: 20px; } }

.AppContainer {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 85px auto;
  grid-template-areas: "m m h h h h h h h h h h" "m m c c c c c c c c c c";
  min-height: 100vh; }

@media (max-width: 768px) {
  .AppContainer {
    grid-template-areas: "h h h h h h h h h h h h" "c c c c c c c c c c c c"; } }

.CreateListButton {
  width: 100px;
  margin-bottom: 30px; }

.MyLists__title {
  color: #443a5a;
  font-weight: bold;
  margin-bottom: 18px;
  font-size: 16px; }

.MyLists__grid {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-bottom: 18px; }

.ListCard {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  height: 100%; }
  .ListCard__image {
    width: 100%;
    height: 190px;
    margin-bottom: 20px; }
    .ListCard__image img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .ListCard__body {
    padding: 20px 30px;
    text-align: center; }
  .ListCard__title {
    color: #443a5a;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 15px; }
  .ListCard__description {
    color: #76728f; }

.MovieCard {
  position: relative; }
  .MovieCard img {
    width: 100%;
    border-radius: 11px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5); }
  .MovieCard span {
    display: block;
    font-weight: bold; }
  .MovieCard .delete-icon {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s ease-out;
    width: 50px;
    height: 50px; }
    .MovieCard .delete-icon svg {
      width: 30px;
      height: 30px; }
  .MovieCard.TextInside span {
    color: #ffffff;
    position: absolute;
    bottom: 30px;
    left: 20px;
    text-shadow: 1px 1px 0 #000; }
  .MovieCard.TextOutside {
    transition: 0.4s ease-out; }
    .MovieCard.TextOutside:hover {
      -webkit-transform: translateY(-15px);
              transform: translateY(-15px); }
      .MovieCard.TextOutside:hover .delete-icon {
        opacity: 1; }
    .MovieCard.TextOutside span {
      text-align: center;
      color: #443a5a;
      font-size: 13px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 10px 0; }

.UpcomingList__title {
  color: #443a5a;
  font-weight: bold;
  margin-bottom: 18px;
  font-size: 16px; }

.UpcomingList__items {
  display: grid;
  grid-column-gap: 17px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); }

.TrendingWrapper {
  display: grid;
  grid-row-gap: 20px; }

.Trending {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); }

.Upcoming {
  display: grid; }

@media (max-width: 768px) {
  .Trending {
    grid-row-gap: 15px; } }

.FilmDetail {
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 4fr 8fr; }
  .FilmDetail__image img {
    width: 100%; }
  .FilmDetail__title {
    font-size: 27px;
    color: #443a5a;
    font-weight: bold;
    margin-bottom: 15px; }
  .FilmDetail__average {
    color: #a7cff1;
    font-size: 35px;
    font-weight: bold; }
    .FilmDetail__average span {
      color: #40445b; }
  .FilmDetail__genres {
    color: #8e9db0;
    margin-bottom: 15px; }
  .FilmDetail__overview {
    line-height: 20px;
    margin-bottom: 15px; }

@media (max-width: 768px) {
  .FilmDetail {
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-template-columns: 5fr 4fr;
    grid-template-rows: auto auto;
    position: relative; }
    .FilmDetail__image img {
      width: 100%; } }

.CreateList {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto; }
  .CreateList__form {
    max-width: 300px;
    min-width: 300px;
    margin-right: 40px; }
    .CreateList__form .input-group {
      margin-bottom: 20px; }
    .CreateList__form .Button {
      margin-top: 20px; }
  .CreateList__films-wrapper {
    margin-top: 20px; }
    .CreateList__films-wrapper .CreateList__film:not(:last-child) {
      margin-bottom: 20px; }
  .CreateList__film {
    font-weight: bold;
    font-style: italic; }
  .CreateList__movies {
    position: relative;
    width: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr)); }
    .CreateList__movies .image-default {
      position: absolute; }
      .CreateList__movies .image-default img {
        width: 100%;
        border-radius: 30px; }
    .CreateList__movies .movie {
      position: relative; }
      .CreateList__movies .movie img {
        width: 100%; }

@media screen and (max-width: 1200px) {
  .CreateList {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .CreateList__form {
      margin-right: 0;
      margin-bottom: 40px; } }

.ListDetail__header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px; }

.ListDetail__grid {
  display: grid;
  grid-column-gap: 17px;
  grid-row-gap: 17px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr)); }


.Loader {
  position: relative;
  width: 100%; }
  .Loader .Spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    width: 65px;
    height: 65px;
    margin-left: -30px;
    margin-top: -20px;
    -webkit-animation: rotator 1.4s linear infinite;
            animation: rotator 1.4s linear infinite;
    z-index: 3; }
    .Loader .Spinner circle {
      stroke: #705b8f; }

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }
  .Loader .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-animation: dash 1.4s ease-in-out infinite;
            animation: dash 1.4s ease-in-out infinite; }

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

@media (max-width: 768px) {
  .Spinner {
    width: 19.11765vw;
    height: 19.11765vw;
    margin-left: -8.82353vw;
    margin-top: -5.88235vw; } }

.AsyncManager {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7); }

.AsyncManager__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0); }

body {
  margin: 0; }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

a {
  color: inherit;
  text-decoration: none;
  color: inherit; }

button {
  -webkit-tap-highlight-color: transparent; }

button,
input {
  border: 0;
  outline: none; }

textarea:focus,
input:focus {
  outline: none; }

h1,
p {
  margin: 0; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  color: inherit;
  text-decoration: none; }

html {
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  font-size: 0.73206vw; }
  @media screen and (min-width: 1280px) {
    html {
      font-size: 10px; } }

body {
  font-family: 'Catamaran';
  font-size: 15px; }

@font-face {
  font-family: 'Catamaran';
  src: url(/static/media/catamaran-regular.2ed839fa.eot);
  src: url(/static/media/catamaran-regular.2ed839fa.eot?#iefix) format("embedded-opentype"), url(/static/media/catamaran-regular.04363326.woff2) format("woff2"), url(/static/media/catamaran-regular.95fb7854.woff) format("woff"), url(/static/media/catamaran-regular.78c8e3a2.ttf) format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Catamaran';
  src: url(/static/media/Catamaran-Bold.52e3af77.eot);
  src: url(/static/media/Catamaran-Bold.52e3af77.eot?#iefix) format("embedded-opentype"), url(/static/media/Catamaran-Bold.23b93819.woff2) format("woff2"), url(/static/media/Catamaran-Bold.9eb979b7.woff) format("woff"), url(/static/media/Catamaran-Bold.cee4a215.ttf) format("truetype");
  font-weight: bold;
  font-style: normal; }


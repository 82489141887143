.ListDetail {
  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }
  &__grid {
    display: grid;
    grid-column-gap: 17px;
    grid-row-gap: 17px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
}

@import 'scss/theme/variables.scss';
@import 'scss/theme/mixins.scss';

html {
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    font-size: get-desktop-vw(10px);
    @media screen and (min-width: $design-width-media-query) {
      font-size: 10px;
    }
  }
  

body{
    font-family: 'Catamaran';
    font-size: get-rem(15px);
}

@import 'scss/theme/variables.scss';
@import 'scss/theme/mixins.scss';


.AsyncManager {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.7);
}

.AsyncManager__loading {
    @include centerer;
}
@import "scss/theme/variables.scss";
@import "scss/theme/mixins.scss";


.Button__signOut {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 30px;
    font-weight: 500;
    letter-spacing: 0.6px;
    color: $white;
    font-size: 15px;
    cursor: pointer;
    &:hover {
      color: $violet4;
    }
    &.active {
      background: linear-gradient(90deg, $violet5 10%, $grey-border 112%);
      color: $violet4;
    }
    img {
        width: 20px;
        height: 20px;
    }
}
@function get-desktop-vw($target) {
  $design-width: 1366;
  $vw-context: ($design-width * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
}

@function get-desktop-vh($target) {
  $design-height: 768;
  $vw-context: ($design-height * 0.01) * 1px;
  @return ($target / $vw-context) * 1vh;
}

@function get-mobile-vw($target) {
  $design-width: 340;
  $vw-context: ($design-width * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
}

@function get-mobile-vh($target) {
  $design-height: 1920;
  $vw-context: ($design-height * 0.01) * 1px;
  @return ($target / $vw-context) * 1vh;
}

@function get-rem($target) {
  // @return ($target * .1 / 1px) * 1rem;
  @return $target;
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

@mixin linear-gradient {
  background: -moz-linear-gradient( rgba(167,207,241,1) 0%, rgba(177,159,216,1) 64%);
  background: -webkit-linear-gradient( rgba(167,207,241,1) 0%, rgba(177,159,216,1) 64%);
  background: linear-gradient( rgba(167,207,241,1) 0%, rgba(177,159,216,1) 64%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a7cff1",endColorstr="#b19fd8",GradientType=1);
}
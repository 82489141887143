@import "scss/theme/variables.scss";
@import "scss/theme/mixins.scss";

.Menu {
  color: $white;
  grid-area: m;
  position: relative;
  overflow: hidden;
  background: linear-gradient($violet, $blue2 90%);
  &__user {
    display: flex;
    align-items: center;
    padding: get-rem(30px);
    border-bottom: 1px solid $grey-border;
    .username {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      > span:first-child {
        font-size: get-rem(17px);
        line-height: get-rem(20px);
      }
      > span:nth-child(2) {
        font-size: get-rem(13px);
      }
    }
    .avatar {
      border-radius: 50%;
      overflow: hidden;
      height: get-rem(40px);
      width: get-rem(40px);
      margin-right: get-rem(20px);
      > img {
        width: 100%;
      }
    }
  }
  &__sections {
    margin: get-rem(30px) 0 get-rem(70px);
    ul {
      li {
        font-weight: 500;
        letter-spacing: 0.6px;
        cursor: pointer;
        a {
          display: block;
          width: 100%;
          padding: get-rem(20px) 0 get-rem(20px) get-rem(30px);
        }
        &:hover {
          color: $violet4;
        }
        &.active {
          background: linear-gradient(90deg, $violet5 10%, $grey-border 112%);
          color: $violet4;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    opacity: 0;
    z-index: -1;
    &.active {
      opacity: 1;
      z-index: 1;
    }
  }
}

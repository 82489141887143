@import 'scss/theme/variables.scss';
@import 'scss/theme/mixins.scss';

.Loader {
    position: relative;
    width: 100%;
    $offset: 187;
    $duration: 1.4s;

    .Spinner {
        @include centerer;
        width: get-rem(65px);
        height: get-rem(65px);
        margin-left: get-rem(-30px);
        margin-top: get-rem(-20px);
        animation: rotator $duration linear infinite;
        z-index: 3;

        circle {
            stroke: $violet2;
        }
    }

    @keyframes rotator {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(270deg);
        }
    }

    .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
            dash $duration ease-in-out infinite;
    }

    @keyframes dash {
        0% {
            stroke-dashoffset: $offset;
        }

        50% {
            stroke-dashoffset: $offset/4;
            transform: rotate(135deg);
        }

        100% {
            stroke-dashoffset: $offset;
            transform: rotate(450deg);
        }
    }
}

@media (max-width: 768px) {
    .Spinner{
        width: get-mobile-vw(65px);
        height: get-mobile-vw(65px);
        margin-left: get-mobile-vw(-30px);
        margin-top: get-mobile-vw(-20px);
    }
}
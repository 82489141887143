@import "scss/theme/variables.scss";
@import "scss/theme/mixins.scss";

.FilmDetail {
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: 4fr 8fr;
  &__image {
    img {
      width: 100%;
    }
  }

  &__title {
    font-size: 27px;
    color: $violet3;
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__average {
    color: $blue2;
    font-size: 35px;
    font-weight: bold;
    span {
      color: $black3;
    }
  }

  &__genres {
    color: $grey;
    margin-bottom: 15px;
  }
  &__overview {
    line-height: 20px;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .FilmDetail {
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    grid-template-columns: 5fr 4fr;
    grid-template-rows: auto auto;
    position: relative;
    &__image {
      img {
        width: 100%;
      }
    }
    &__overview {
      // position: absolute;
      // bottom: -60px;
      // left: 0;
    }
  }
}

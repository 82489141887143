@import "scss/theme/variables.scss";
@import "scss/theme/mixins.scss";

.AppContent {
  grid-area: c;
  background-color: $white;
  padding: 20px 40px;
}

@media (max-width: 768px) {
  .AppContent {
    padding: 20px;
  }
}

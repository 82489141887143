@import "scss/theme/variables.scss";
@import "scss/theme/mixins.scss";

.TrendingWrapper {
  display: grid;
  grid-row-gap: 20px;
}

.Trending {
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.Upcoming {
  display: grid;
  // grid-template-columns: repeat(2, auto);
}

@media (max-width: 768px) {
  .Trending {
    grid-row-gap: 15px;
  }
}

@import 'scss/theme/variables.scss';
@import 'scss/theme/mixins.scss';

.MovieCard {
  position: relative;
  img {
    width: 100%;
    border-radius: get-rem(11px);
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  }
  span {
    display: block;
    font-weight: bold;
  }
  .delete-icon {
    opacity: 0;
    @include centerer;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s ease-out;
    width: 50px;
    height: 50px;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  &.TextInside {
    span {
      color: $white;
      position: absolute;
      bottom: get-rem(30px);
      left: get-rem(20px);
      text-shadow: 1px 1px 0 #000;
    }
  }
  &.TextOutside {
    transition: 0.4s ease-out;
    &:hover {
      transform: translateY(-15px);
      .delete-icon {
        opacity: 1;
      }
    }

    span {
      text-align: center;
      color: $violet3;
      font-size: 13px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 10px 0;
    }
  }
}

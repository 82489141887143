$design-width: 1200px;
$design-width-media-query: $design-width + 80px;


//colors
$white: #ffffff;
$white2: #f9fafb;

$blue: #2918e2;
$blue2: #a7cff1;
$blue3: #cad5e1;

$violet: #b19fd8;
$violet2: #705b8f;
$violet3: #443a5a;
$violet4: #76728f;
$violet5: #d1c8e4;

$green: #C1EC4D; 



$black: #000000;
$black2: #1f2833;
$black3: #40445b;

$grey-bg: #e3e2df;
$grey: #8e9db0;
$grey-border: #cacad9;

@import "scss/theme/variables.scss";
@import "scss/theme/mixins.scss";

.CreateListButton {
  width: 100px;
  margin-bottom: 30px;
}
.MyLists {
  &__title {
    color: $violet3;
    font-weight: bold;
    margin-bottom: 18px;
    font-size: 16px;
  }
  &__grid {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));
    margin-bottom: 18px;
  }
}

.ListCard {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  height: 100%;
  &__image {
    width: 100%;
    height: 190px;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__body {
    padding: 20px 30px;
    text-align: center;
  }
  &__title {
    color: $violet3;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 15px;
  }
  &__description {
    color: $violet4
  }
 
}

@media (max-width: 768px) {
  .MyLists {
  }
}

$path: '../../assets/fonts/catamaran/';


@font-face {
    font-family: 'Catamaran';
    src: url('#{$path}/catamaran-regular.eot');
    src: url('#{$path}/catamaran-regular.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/catamaran-regular.woff2') format('woff2'),
    url('#{$path}/catamaran-regular.woff') format('woff'),
    url('#{$path}/catamaran-regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Catamaran';
    src: url('#{$path}/Catamaran-Bold.eot');
    src: url('#{$path}/Catamaran-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$path}/Catamaran-Bold.woff2') format('woff2'),
    url('#{$path}/Catamaran-Bold.woff') format('woff'),
    url('#{$path}/Catamaran-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


// @font-face {
//     font-family: 'Catamaran';
//     src: url('#{$path}/Catamaran-light.eot');
//     src: url('#{$path}/Catamaran-light.eot?#iefix') format('embedded-opentype'),
//     url('#{$path}/Catamaran-light.woff2') format('woff2'),
//     url('#{$path}/Catamaran-light.woff') format('woff'),
//     url('#{$path}/Catamaran-light.ttf') format('truetype');
//     font-weight: 300;
//     font-style: normal;
// }
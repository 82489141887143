@import "scss/theme/variables.scss";
@import "scss/theme/mixins.scss";

.AppHeader {
  grid-area: h;
  background-color: $white;
  padding: 20px 0;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  // color: $white;
  border-bottom: 1px solid $grey-border;
  &__select {
    width: 100%;
    max-width: 350px;
  }
}
@media (max-width: 768px) {
  .AppHeader {
    &__select {
      width: 70%;
    }
  }
}

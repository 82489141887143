.CreateList {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  &__form {
    max-width: 300px;
    min-width: 300px;
    margin-right: 40px;
    .input-group {
      margin-bottom: 20px;
    }
    .Button {
      margin-top: 20px;
    }
  }
  &__films-wrapper {
    margin-top: 20px;
    .CreateList__film:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &__film {
    font-weight: bold;
    font-style: italic;
  }
  &__movies {
    position: relative;
    width: 100%;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    .image-default {
      position: absolute;
      img {
        width: 100%;
        border-radius: 30px;
      }
    }
    .movie {
      position: relative;
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .CreateList {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__form {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
}
